.chakra-ui-light {
  --light-gray: var(--chakra-colors-gray-200);
  --gray: var(--chakra-colors-gray-300);
  --blue700: var(--chakra-colors-blue-600);
  --blue600: var(--chakra-colors-blue-500);
  --blue500: var(--chakra-colors-gray-400);
  --blue400: var(--chakra-colors-gray-300);
  --blue300: var(--chakra-colors-gray-200);
  --blue200: var(--chakra-colors-gray-200);
  --blue100: var(--chakra-colors-gray-100);
  --blue50: var(--chakra-colors-gray-50);
  --monthBackground: var(--chakra-colors-white);
  --text: var(--chakra-colors-black);
  --negative-text: var(--chakra-colors-white);
}
.chakra-ui-dark {
  --light-gray: var(--chakra-colors-gray-600);
  --gray: var(--chakra-colors-gray-500);
  --blue700: var(--chakra-colors-blue-600);
  --blue600: var(--chakra-colors-blue-300);
  --blue500: var(--chakra-colors-gray-500);
  --blue400: var(--chakra-colors-gray-600);
  --blue300: var(--chakra-colors-gray-700);
  --blue200: var(--chakra-colors-gray-600);
  --blue100: var(--chakra-colors-gray-800);
  --blue50: var(--chakra-colors-gray-600);
  --monthBackground: var(--chakra-colors-gray-700);
  --text: var(--chakra-colors-gray-200);
  --negative-text: var(--chakra-colors-black);
}

/* if you dont want to use chakra's theme use this class in the wrapping div. These are the exact original values */
.light-theme-original {
  --light-gray: #cccccc;
  --gray: #b3b3b3;
  --blue700: #2a69ac;
  --blue600: #3182ce;
  --blue500: #a0aec0;
  --blue400: #cbd5e0;
  --blue300: #e2e8f0;
  --blue200: #edf2f7;
  --blue100: #f7fafc;
}
.react-datepicker {
  font-family: unset;
  font-size: 0.9rem;
  border-color: var(--light-gray);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container {
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid;
  border-color: var(--light-gray);
  background-color: white;
}
.react-datepicker__input-text {
  background-color: transparent;
}

.react-datepicker-sm .react-datepicker__input-container {
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  height: 2rem;
  border-radius: 0.25rem;
}

.react-datepicker__input-container:hover {
  border-color: var(--gray);
}
.react-datepicker__input-container:focus-within {
  z-index: 1;
  border-color: var(--blue600);
  box-shadow: 0 0 0 1px var(--blue600);
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 100%;
  outline: 0;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  /*height: 8px;*/
  /*  HACK EHESS */
  height: 24px;
  top: 12px;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--blue400);
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--blue500);
}

.react-datepicker__navigation--next {
  border-left-color: var(--blue400);
}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--blue500);
}

.react-datepicker__header {
  background-color: var(--blue100);
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--blue300);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 4px;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background: white;
  border-color: var(--light-gray);
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: var(--blue100);
}

.react-datepicker__year-option:first-child:hover,
.react-datepicker__month-option:first-child:hover,
.react-datepicker__month-year-option:first-child:hover,
.react-datepicker__year-option:last-child:hover,
.react-datepicker__month-option:last-child:hover,
.react-datepicker__month-year-option:last-child:hover {
  background-color: var(--blue50);
}

.react-datepicker__navigation--years::before {
  transform: rotate(135deg);
  border-color: var(--blue400);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  left: 12px;
  width: 9px;
}

.react-datepicker__navigation--years-previous::before {
  bottom: 17px;
  transform: rotate(135deg);
}

.react-datepicker__navigation--years-upcoming::before {
  top: 17px;
  transform: rotate(-45deg);
}

.react-datepicker__header__dropdown--select {
  margin-top: 4px;
}

select.react-datepicker__month-select,
select.react-datepicker__year-select {
  border: 1px solid var(--blue400);
  border-radius: 4px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--text);
}

.react-datepicker__month {
  background-color: var(--monthBackground);
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--blue200);
}

.react-datepicker__day {
  color: var(--text);
}

.react-datepicker__day:hover {
  background: var(--blue200);
}

.react-datepicker__day-name {
  color: var(--text);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--blue600);
  font-weight: normal;
  color: var(--negative-text);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: var(--blue700);
}

.react-datepicker__close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  padding: 0 2px 2px;
  font-weight: bold;
  color: var(--light-gray);
  height: auto;
  width: auto;
}

.react-datepicker__close-icon::after:hover {
  color: var(--gray);
}
/*Disable color on other lines https://stackoverflow.com/questions/63284785/react-datepicker-remove-coloring-of-same-day-in-other-months */
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
