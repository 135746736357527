.row-red, .row-red div, .row-red:hover, .row-red div:hover  {
    /*background-color: var(--chakra-colors-orange-100);*/
}

.icon-row {
    font-size: 24px;
}

.rs-table-row {
    font-size: 12px;
}

.row-blue, .row-blue div, .row-blue:hover, .row-blue div:hover {
    background-color: var(--blue100);
}

.ghost-table {
    opacity: 0.5;
}

.with-line-breaks {
    white-space: pre-line;
}